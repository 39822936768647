// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-js": () => import("./../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kosten-js": () => import("./../src/pages/kosten.js" /* webpackChunkName: "component---src-pages-kosten-js" */),
  "component---src-pages-praxis-js": () => import("./../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-service-template-js": () => import("./../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-team-template-js": () => import("./../src/templates/team-template.js" /* webpackChunkName: "component---src-templates-team-template-js" */)
}

